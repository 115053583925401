import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";

const Header = () => {
  return (
    <header className="header">
      <div className="header_container">
        <img src={require("../../images/vtc-elite.webp")} alt="vtc" />
        <nav>
          <Link to="/">Accueil</Link>
          <Link to="/fixed-price">Prix fixes</Link>
          <Link to="/kilometer-price">Prix au kilomètre</Link>
        </nav>
      </div>
    </header>
  );
};

export default Header;
