import axios from "axios";
import { toast } from "react-toastify";
import { PRICES_URL } from "../api/priceApi";
import {
  getPricesFailed,
  getPricesRequest,
  getPricesSuccess,
  updatePriceFailed,
  updatePriceRequest,
  updatePriceSuccess,
} from "../reducers/priceReducer";

export const getPrices = (token, dispatch) => {
  dispatch(getPricesRequest());
  axios
    .get(PRICES_URL, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch(getPricesSuccess(res.data));
    })
    .catch((err) => {
      dispatch(getPricesFailed());
    });
};

export const updatePrice = (id, body, closeModal, dispatch) => {
  dispatch(updatePriceRequest());
  axios
    .put(`${PRICES_URL}/${id}`, body)
    .then((res) => {
      closeModal();
      toast(res.data.message, { type: "success" });
      dispatch(updatePriceSuccess());
      getPrices(dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(updatePriceFailed());
    });
};
