import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, InputNumber, Modal, Row } from "antd";
import React, { useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { getAdminDistance } from "../../redux/actions/distanceActions";
import { addItinerary } from "../../redux/actions/itineraryActions";
import { filteredTime } from "../../utils/filteredTime";
import "./styles.css";

const searchOptions = {
  componentRestrictions: {
    country: "fr",
  },
};

function ItineraryModal(props) {
  const { token } = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  const { closeModal, itinerary } = props;
  const [form] = Form.useForm();
  const [originValue, setOriginValue] = useState("");
  const [informations, setInformations] = useState(
    itinerary ? itinerary : null
  );
  const [destinationValue, setDestinationValue] = useState("");

  const onSelectOrigin = async (address) => {
    let fields = form.getFieldsValue();
    fields = {
      ...fields,
      origin: address,
    };
    setOriginValue(address);
    form.setFieldsValue(fields);
  };

  const onSelectDestination = async (address) => {
    let fields = form.getFieldsValue();
    fields = {
      ...fields,
      destination: address,
    };
    setDestinationValue(address);
    form.setFieldsValue(fields);
  };

  const onFinish = async (values) => {
    if (informations) {
      let body = {
        ...informations,
        prices: {
          confort: {
            day: values.confortDay,
            night: values.confortNight,
            weDay: values.confortWeDay,
            weNight: values.confortWeNight,
          },
          business: {
            day: values.businessDay,
            night: values.businessNight,
            weDay: values.businessWeDay,
            weNight: values.businessWeNight,
          },
          van: {
            day: values.vanDay,
            night: values.vanNight,
            weDay: values.vanWeDay,
            weNight: values.vanWeNight,
          },
        },
      };
      addItinerary(token, body, closeModal, dispatch);
    } else {
      const origin = await geocodeByAddress(values.origin);
      const originLatLng = await getLatLng(origin[0]);
      const destination = await geocodeByAddress(values.destination);
      const destinationLatLng = await getLatLng(destination[0]);
      console.log(origin[0].address_components);
      const originCity = origin[0].address_components.filter(
        (item) =>
          item.types.includes("locality") || item.types.includes("postal_town")
      )[0].long_name;
      const originPostalCode = origin[0].address_components.filter((item) =>
        item.types.includes("postal_code")
      )[0].long_name;
      const destinationCity = destination[0].address_components.filter(
        (item) =>
          item.types.includes("locality") || item.types.includes("postal_town")
      )[0].long_name;
      const destinationPostalCode = destination[0].address_components.filter(
        (item) => item.types.includes("postal_code")
      )[0].long_name;
      let body = {
        ...values,
        originCity,
        originPostalCode,
        originLatLng,
        destinationCity,
        destinationPostalCode,
        destinationLatLng,
      };
      getAdminDistance(body)
        .then((res) => {
          setInformations(res.data);
          let fields = form.getFieldsValue();
          fields = {
            ...fields,
            origin: res.data.origin,
            destination: res.data.destination,
            distance: res.data.distance,
            distanceValue: res.data.distanceValue,
            duration: res.data.duration,
            confortDay: res.data.prices.confort.day,
            confortNight: res.data.prices.confort.night,
            confortWeDay: res.data.prices.confort.weDay,
            confortWeNight: res.data.prices.confort.weNight,
            businessDay: res.data.prices.business.day,
            businessNight: res.data.prices.business.night,
            businessWeDay: res.data.prices.business.weDay,
            businessWeNight: res.data.prices.business.weNight,
            vanDay: res.data.prices.van.day,
            vanNight: res.data.prices.van.night,
            vanWeDay: res.data.prices.van.weDay,
            vanWeNight: res.data.prices.van.weNight,
          };
          form.setFieldsValue(fields);
          console.log(res.data);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <Modal
      title={itinerary ? "Modifier l'itineraire" : "Ajouter un itineraire"}
      open={true}
      onCancel={closeModal}
      footer={() => null}
      width={900}
    >
      <Form
        layout="vertical"
        onFinish={onFinish}
        form={form}
        initialValues={
          itinerary
            ? {
                originName: itinerary.originName,
                origin: itinerary.origin,
                destinationName: itinerary.destinationName,
                destination: itinerary.destination,
                distance: itinerary.distance,
                distanceValue: itinerary.distanceValue,
                duration: itinerary.duration,
                confortDay: itinerary.prices.confort.day,
                confortNight: itinerary.prices.confort.night,
                confortWeDay: itinerary.prices.confort.weDay,
                confortWeNight: itinerary.prices.confort.weNight,
                businessDay: itinerary.prices.business.day,
                businessNight: itinerary.prices.business.night,
                businessWeDay: itinerary.prices.business.weDay,
                businessWeNight: itinerary.prices.business.weNight,
                vanDay: itinerary.prices.van.day,
                vanNight: itinerary.prices.van.night,
                vanWeDay: itinerary.prices.van.weDay,
                vanWeNight: itinerary.prices.van.weNight,
              }
            : {
                originName: "",
                origin: "",
                destinationName: "",
                destination: "",
                distance: "",
                distanceValue: "",
                duration: "",
                confortDay: "",
                confortNight: "",
                confortWeDay: "",
                confortWeNight: "",
                businessDay: "",
                businessNight: "",
                businessWeDay: "",
                businessWeNight: "",
                vanDay: "",
                vanNight: "",
                vanWeDay: "",
                vanWeNight: "",
              }
        }
      >
        {!informations && (
          <>
            <Form.Item
              label="Nom du lieu de départ"
              name="originName"
              rules={[
                {
                  required: true,
                  message: "Veuillez ajoiuter le nom du lieu d'origine",
                },
              ]}
            >
              <Input placeholder="Gare de Creil" />
            </Form.Item>
            <Form.Item
              name="origin"
              label="Adresse de départ"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <PlacesAutocomplete
                onSelect={(address) => onSelectOrigin(address)}
                value={originValue}
                onChange={() => null}
                searchOptions={searchOptions}
              >
                {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                  <div>
                    <Input
                      {...getInputProps({
                        className: "location-search-input",
                      })}
                      placeholder="Place Charles-de-Gaulle 60100 Creil"
                    />
                    <div className="autocomplete-dropdown-container">
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? {
                              backgroundColor: "#fafafa",
                              cursor: "pointer",
                              padding: "5px 10px",
                              borderBottom: "1px solid lightgray",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              width: "100%",
                              boxSizing: "border-box",
                              fontSize: 12,
                            }
                          : {
                              backgroundColor: "#ffffff",
                              cursor: "pointer",
                              padding: "5px 10px",
                              borderBottom: "1px solid lightgray",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              width: "100%",
                              boxSizing: "border-box",
                              fontSize: 12,
                            };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </Form.Item>
            <Form.Item
              label="Nom du lieu d'arrivée"
              name="destinationName"
              tooltip={{
                title: "Ceci est le nom de la catégorie de prestation",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Veuillez ajouter le nom de la catégorie!",
                },
              ]}
            >
              <Input placeholder="Aéroport Charles de Gaulle" />
            </Form.Item>
            <Form.Item
              label="Adresse d'arrivée"
              name="destination"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <PlacesAutocomplete
                value={destinationValue}
                onSelect={(address, placeId) =>
                  onSelectDestination(address, placeId)
                }
                searchOptions={searchOptions}
                onChange={() => null}
              >
                {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                  <div>
                    <Input
                      {...getInputProps({
                        className: "location-search-input",
                      })}
                      placeholder="6 rue des bruyères 95931 Roissy Charles de Gaulle"
                    />
                    <div className="autocomplete-dropdown-container">
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? {
                              backgroundColor: "#fafafa",
                              cursor: "pointer",
                              padding: "5px 10px",
                              borderBottom: "1px solid lightgray",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              width: "100%",
                              boxSizing: "border-box",
                              fontSize: 12,
                            }
                          : {
                              backgroundColor: "#ffffff",
                              cursor: "pointer",
                              padding: "5px 10px",
                              borderBottom: "1px solid lightgray",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              width: "100%",
                              boxSizing: "border-box",
                              fontSize: 12,
                            };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </Form.Item>
          </>
        )}
        {informations && (
          <>
            <div
              style={{
                border: "1px solid lightgrey",
                padding: 10,
              }}
            >
              <Row>
                <Col span={6}>Nom du lieu de départ : </Col>
                <Col>{informations.originName}</Col>
              </Row>
              <Row>
                <Col span={6}>Adresse de départ : </Col>
                <Col>{informations.origin}</Col>
              </Row>
              <Row>
                <Col span={6}>Nom du lieu d'arrivée : </Col>
                <Col>{informations.destinationName}</Col>
              </Row>
              <Row>
                <Col span={6}>Adresse d'arrivée : </Col>
                <Col>{informations.destination}</Col>
              </Row>
              <Row>
                <Col span={6}>Distance : </Col>
                <Col>{informations.distance}</Col>
              </Row>
              <Row>
                <Col span={6}>Durée : </Col>
                <Col>{filteredTime(informations.duration / 60)}</Col>
              </Row>
            </div>
            <div
              style={{
                marginTop: 10,
                border: "1px solid lightgrey",
                padding: 10,
              }}
            >
              <h3 style={{ margin: "0 0 10px 0" }}>Prix fixes</h3>
              <h4 style={{ margin: "0 0 5px 0" }}>Confort</h4>
              <Row gutter={20}>
                <Col span={6}>
                  <Form.Item
                    label="Jour"
                    name="confortDay"
                    rules={[
                      {
                        required: true,
                        message: "Veuillez ajoiuter le nom du lieu d'origine",
                      },
                    ]}
                  >
                    <InputNumber placeholder="Gare de Creil" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Nuit"
                    name="confortNight"
                    rules={[
                      {
                        required: true,
                        message: "Veuillez ajoiuter le nom du lieu d'origine",
                      },
                    ]}
                  >
                    <InputNumber placeholder="Gare de Creil" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Week-End"
                    name="confortWeDay"
                    rules={[
                      {
                        required: true,
                        message: "Veuillez ajoiuter le nom du lieu d'origine",
                      },
                    ]}
                  >
                    <InputNumber placeholder="Gare de Creil" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Week-End nuit"
                    name="confortWeNight"
                    rules={[
                      {
                        required: true,
                        message: "Veuillez ajoiuter le nom du lieu d'origine",
                      },
                    ]}
                  >
                    <InputNumber placeholder="Gare de Creil" />
                  </Form.Item>
                </Col>
              </Row>
              <h4 style={{ margin: "0 0 5px 0" }}>Affaire</h4>
              <Row gutter={20}>
                <Col span={6}>
                  <Form.Item
                    label="Jour"
                    name="businessDay"
                    rules={[
                      {
                        required: true,
                        message: "Veuillez ajoiuter le nom du lieu d'origine",
                      },
                    ]}
                  >
                    <InputNumber placeholder="Gare de Creil" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Nuit"
                    name="businessNight"
                    rules={[
                      {
                        required: true,
                        message: "Veuillez ajoiuter le nom du lieu d'origine",
                      },
                    ]}
                  >
                    <InputNumber placeholder="Gare de Creil" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Week-End"
                    name="businessWeDay"
                    rules={[
                      {
                        required: true,
                        message: "Veuillez ajoiuter le nom du lieu d'origine",
                      },
                    ]}
                  >
                    <InputNumber placeholder="Gare de Creil" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Week-End nuit"
                    name="businessWeNight"
                    rules={[
                      {
                        required: true,
                        message: "Veuillez ajoiuter le nom du lieu d'origine",
                      },
                    ]}
                  >
                    <InputNumber placeholder="Gare de Creil" />
                  </Form.Item>
                </Col>
              </Row>
              <h4 style={{ margin: "0 0 5px 0" }}>Van</h4>
              <Row gutter={20}>
                <Col span={6}>
                  <Form.Item
                    label="Jour"
                    name="vanDay"
                    rules={[
                      {
                        required: true,
                        message: "Veuillez ajoiuter le nom du lieu d'origine",
                      },
                    ]}
                  >
                    <InputNumber placeholder="Gare de Creil" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Nuit"
                    name="vanNight"
                    rules={[
                      {
                        required: true,
                        message: "Veuillez ajoiuter le nom du lieu d'origine",
                      },
                    ]}
                  >
                    <InputNumber placeholder="Gare de Creil" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Week-End"
                    name="vanWeDay"
                    rules={[
                      {
                        required: true,
                        message: "Veuillez ajoiuter le nom du lieu d'origine",
                      },
                    ]}
                  >
                    <InputNumber placeholder="Gare de Creil" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Week-End nuit"
                    name="vanWeNight"
                    rules={[
                      {
                        required: true,
                        message: "Veuillez ajoiuter le nom du lieu d'origine",
                      },
                    ]}
                  >
                    <InputNumber placeholder="Gare de Creil" />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </>
        )}
        <div
          style={{
            border: "1px solid lightgrey",
            padding: 10,
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 10,
          }}
        >
          <Button type="primary" onClick={() => setInformations(null)}>
            Modifier le trajet
          </Button>
          <Button style={{ marginLeft: 20 }} type="primary" htmlType="submit">
            Enregister
          </Button>
        </div>
      </Form>
    </Modal>
  );
}

export default ItineraryModal;
