import "./App.css";
import Navigation from "./navigation/Navigation";
import { Provider } from "react-redux";
import store from "./redux/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ConfigProvider } from "antd";
import locale from "antd/locale/fr_FR";
import dayjs from "dayjs";

dayjs.locale("fr");

function App() {
  return (
    <Provider store={store}>
      <ConfigProvider
        locale={locale}
        theme={{
          token: {
            colorPrimary: "#273747",
          },
        }}
      >
        <ToastContainer autoClose={2000} theme="colored" />
        <Navigation />
      </ConfigProvider>
    </Provider>
  );
}

export default App;
