import { createSlice } from "@reduxjs/toolkit";

export const itinerarySlice = createSlice({
  name: "itineraryReducer",
  initialState: {
    itineraries: [],
    itinerariesLoading: false,
    itineraryLoading: false,
  },
  reducers: {
    getItinerariesRequest: (state) => {
      state.itinerariesLoading = true;
    },
    getItinerariesSuccess: (state, action) => {
      state.itinerariesLoading = false;
      state.itineraries = action.payload;
    },
    getItinerariesFailed: (state) => {
      state.itinerariesLoading = false;
    },
    addItineraryRequest: (state) => {
      state.itineraryLoading = true;
    },
    addItinerarySuccess: (state, action) => {
      state.itineraryLoading = false;
    },
    addItineraryFailed: (state) => {
      state.itineraryLoading = false;
    },
  },
});

export const {
  getItinerariesRequest,
  getItinerariesSuccess,
  getItinerariesFailed,
  addItineraryRequest,
  addItinerarySuccess,
  addItineraryFailed,
} = itinerarySlice.actions;

export default itinerarySlice.reducer;
