import { createSlice } from "@reduxjs/toolkit";

export const priceSlice = createSlice({
  name: "priceReducer",
  initialState: {
    prices: null,
    pricesLoading: false,
  },
  reducers: {
    getPricesRequest: (state) => {
      state.pricesLoading = true;
    },
    getPricesSuccess: (state, action) => {
      state.pricesLoading = false;
      state.prices = action.payload;
    },
    getPricesFailed: (state) => {
      state.pricesLoading = false;
    },
    updatePriceRequest: (state) => {
      state.pricesLoading = true;
    },
    updatePriceSuccess: (state) => {
      state.pricesLoading = false;
    },
    updatePriceFailed: (state) => {
      state.pricesLoading = false;
    },
  },
});

export const {
  getPricesRequest,
  getPricesSuccess,
  getPricesFailed,
  updatePriceRequest,
  updatePriceSuccess,
  updatePriceFailed,
} = priceSlice.actions;

export default priceSlice.reducer;
