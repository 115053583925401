import axios from "axios";
import { toast } from "react-toastify";
import { RESERVATION_URL } from "../api/reservationApi";
import {
  getReservationsFailed,
  getReservationsRequest,
  getReservationsSuccess,
} from "../reducers/reservationReducer";

export const getReservations = (token, dispatch) => {
  dispatch(getReservationsRequest());
  return axios
    .get(RESERVATION_URL, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch(getReservationsSuccess(res.data));
    })
    .catch((err) => {
      dispatch(getReservationsFailed());
      toast(err.response.data.message, { type: "error" });
    });
};
