import { LoadingOutlined } from "@ant-design/icons";
import { Button, Form, Input, Spin } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../redux/actions/userActions";
import "./styles.css";

const Login = () => {
  const { userLoading } = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  const onFinish = (values) => {
    loginUser(values, dispatch);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="login_container">
      <div className="login_form">
        <Form
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            label="Addresse Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Veuillez renseigner votre adresse Email!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Mot de passe"
            name="password"
            rules={[
              {
                required: true,
                message: "Veuillez renseigner votre mot de passe!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <Button
              className="button_primary"
              type="primary"
              htmlType="submit"
              disabled={userLoading}
            >
              Se connecter
              {userLoading && (
                <Spin indicator={<LoadingOutlined spin />} size="small" />
              )}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;
