import { configureStore } from "@reduxjs/toolkit";
import itineraryReducer from "./reducers/itineraryReducer";
import priceReducer from "./reducers/priceReducer";
import reservationReducer from "./reducers/reservationReducer";
import userReducer from "./reducers/userReducer";

export default configureStore({
  reducer: {
    userReducer: userReducer,
    priceReducer: priceReducer,
    itineraryReducer: itineraryReducer,
    reservationReducer: reservationReducer,
  },
});
