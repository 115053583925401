import { Button, Col, Image, Row } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { PriceModal } from "../../components";
import { filteredPrice } from "../../utils/filteredPrice";
import "./styles.css";

const KilometrePrices = () => {
  const { prices } = useSelector((state) => state.priceReducer);
  const [selectedPrice, setSelectedPrice] = useState(null);

  const onSelectedPrice = (price) => {
    setSelectedPrice(price);
  };
  const onCloseModal = () => {
    setSelectedPrice(null);
  };

  return (
    <div className="kilometer_prices_container">
      {selectedPrice && (
        <PriceModal price={selectedPrice} closeModal={onCloseModal} />
      )}
      <h1 className="textCenter">Prix au kilomètre</h1>
      <Row gutter={20}>
        {prices.map((price) => (
          <Col span={8} key={price._id}>
            <div className="kilometer_prices_item">
              <h2 className="textCenter">{price.name}</h2>
              <Image
                preview={false}
                src={price.image}
                style={{ objectFit: "cover", objectPosition: "center" }}
              />
              <div>
                <div className="kilometer_price_container">
                  <div className="kilometer_price_text">Prix journée :</div>
                  <div className="kilometer_price_text_bold">
                    {filteredPrice(price.day)}
                  </div>
                </div>
                <div className="kilometer_price_container">
                  <div className="kilometer_price_text">Prix nuit :</div>
                  <div className="kilometer_price_text_bold">
                    {filteredPrice(price.night)}
                  </div>
                </div>
                <div className="kilometer_price_container">
                  <div className="kilometer_price_text">Prix week-end :</div>
                  <div className="kilometer_price_text_bold">
                    {filteredPrice(price.weDay)}
                  </div>
                </div>
                <div className="kilometer_price_container">
                  <div className="kilometer_price_text">
                    Prix week-end nuit :
                  </div>
                  <div className="kilometer_price_text_bold">
                    {filteredPrice(price.weNight)}
                  </div>
                </div>
              </div>
              <Button
                type="primary"
                onClick={() => onSelectedPrice(price)}
                className="button_primary"
              >
                Modifer les prix
              </Button>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default KilometrePrices;
