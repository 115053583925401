import { Button, Col, Row } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ItineraryModal } from "../../components";
import { filteredPrice } from "../../utils/filteredPrice";
import { filteredTime } from "../../utils/filteredTime";
import "./styles.css";

const FixedPrices = () => {
  const { itineraries } = useSelector((state) => state.itineraryReducer);
  const [toggleModal, setToggleModal] = useState({
    active: false,
    itinerary: null,
  });
  const onShowModal = () => {
    setToggleModal((prevState) => ({
      ...prevState,
      active: true,
    }));
  };
  const onHideModal = () => {
    setToggleModal((prevState) => ({
      ...prevState,
      active: false,
      itinerary: null,
    }));
  };
  const onSelectItinerary = (itinerary) => {
    setToggleModal((prevState) => ({
      ...prevState,
      active: true,
      itinerary: itinerary,
    }));
  };
  return (
    <div className="itineraries_container">
      {toggleModal.active && (
        <ItineraryModal
          itinerary={toggleModal.itinerary}
          closeModal={onHideModal}
        />
      )}
      <div className="addItineraryButtonContainer">
        <Button onClick={onShowModal}>Ajouter un trajet</Button>
      </div>
      <div className="addItineraryRowContainer mt30">
        <Row>
          <Col span={4}>Lieu de départ</Col>
          <Col span={4}>Lieu d'arrivée</Col>
          <Col span={4} style={{ textAlign: "center" }}>
            Temps de trajet
          </Col>
          <Col span={4} style={{ textAlign: "center" }}>
            Prix minimum
          </Col>
          <Col span={4} style={{ textAlign: "center" }}>
            Prix maximum
          </Col>
          <Col span={4} style={{ textAlign: "center" }}>
            Actions
          </Col>
        </Row>
      </div>
      {itineraries.map((itinerary) => (
        <div className="addItineraryRowContainer" key={itinerary._id}>
          <Row>
            <Col span={4}>{itinerary.originName}</Col>
            <Col span={4}>{itinerary.destinationName}</Col>
            <Col span={4} style={{ textAlign: "center" }}>
              {filteredTime(itinerary.duration / 60)}
            </Col>
            <Col span={4} style={{ textAlign: "center" }}>
              {filteredPrice(itinerary.prices.confort.day)}
            </Col>
            <Col span={4} style={{ textAlign: "center" }}>
              {filteredPrice(itinerary.prices.van.weNight)}
            </Col>
            <Col span={4} style={{ textAlign: "center" }}>
              <Button onClick={() => onSelectItinerary(itinerary)}>
                Modifier le trajet
              </Button>
            </Col>
          </Row>
        </div>
      ))}
    </div>
  );
};

export default FixedPrices;
