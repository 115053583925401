import { createSlice } from "@reduxjs/toolkit";

export const priceSlice = createSlice({
  name: "reservationReducer",
  initialState: {
    reservations: [],
    reservationsLoading: false,
  },
  reducers: {
    getReservationsRequest: (state) => {
      state.reservationsLoading = true;
    },
    getReservationsSuccess: (state, action) => {
      state.reservationsLoading = false;
      state.reservations = action.payload;
    },
    getReservationsFailed: (state) => {
      state.reservationsLoading = false;
    },
  },
});

export const {
  getReservationsRequest,
  getReservationsSuccess,
  getReservationsFailed,
} = priceSlice.actions;

export default priceSlice.reducer;
