import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Footer, Header } from "../layouts";
import { FixedPrices, Home, KilometrePrices, Login } from "../pages";
import { getitineraries } from "../redux/actions/itineraryActions";
import { getPrices } from "../redux/actions/priceActions";
import { getReservations } from "../redux/actions/reservationActions";
import { getUser } from "../redux/actions/userActions";

const Navigation = () => {
  const { token } = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    const tokenFromStorage = localStorage.getItem("token");
    if (tokenFromStorage) {
      let body = {
        token: tokenFromStorage,
      };
      getUser(body, dispatch);
    }
  }, [dispatch]);

  useEffect(() => {
    if (token) {
      getPrices(token, dispatch);
      getitineraries(token, dispatch);
      getReservations(token, dispatch);
    }
  }, [dispatch, token]);

  const appNavigator = () => (
    <BrowserRouter>
      <Header />
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/fixed-price" element={<FixedPrices />} />
          <Route path="/kilometer-price" element={<KilometrePrices />} />
          <Route path="/*" element={<Navigate replace to="/" />} />
        </Routes>
      </main>
      <Footer />
    </BrowserRouter>
  );
  const authNavigator = () => (
    <BrowserRouter>
      <main>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/*" element={<Navigate replace to="/login" />} />
        </Routes>
      </main>
    </BrowserRouter>
  );
  return token ? appNavigator() : authNavigator();
};

export default Navigation;
