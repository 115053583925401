import { Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { filteredPrice } from "../../utils/filteredPrice";
import "./styles.css";

const Home = () => {
  const { reservations } = useSelector((state) => state.reservationReducer);
  return (
    <div className="home_container">
      <Row className="border-bottom" gutter={5}>
        <Col span={2}>
          <div className="p10">Type de transport</div>
        </Col>
        <Col span={4}>
          <div className="p10">Adresse de départ</div>
        </Col>
        <Col span={4}>
          <div className="p10">Adresse de destination</div>
        </Col>
        <Col span={2}>
          <div className="p10">Trajet</div>
        </Col>
        <Col span={3}>
          <div className="p10">Date et heure</div>
        </Col>
        <Col span={2}>
          <div className="p10">Nbr de personne</div>
        </Col>
        <Col span={2}>
          <div className="p10">Nbr de bagage</div>
        </Col>
        <Col span={3}>
          <div className="p10">Informations client</div>
        </Col>
        <Col span={2}>
          <div className="p10">Prix de la course</div>
        </Col>
      </Row>
      {reservations.map((reservation) => (
        <Row gutter={5} className="border-bottom" key={reservation._id}>
          <Col span={2}>
            <div className="p10">{reservation.travel.type}</div>
          </Col>
          <Col span={4}>
            <div className="p10">{reservation.travel.forth.origin}</div>
          </Col>
          <Col span={4}>
            <div className="p10">{reservation.travel.forth.destination}</div>
          </Col>
          <Col span={2}>
            <div className="p10">
              {reservation.travel.backAndForth
                ? "Aller-Retour"
                : "Aller simple"}
            </div>
          </Col>
          <Col span={3}>
            <div className="p10">
              {new Date(reservation.travel.forth.date).toLocaleString("fr")}
            </div>
          </Col>
          <Col span={2}>
            <div className="p10">{reservation.travel.forth.person}</div>
          </Col>
          <Col span={2}>
            <div className="p10">{reservation.travel.forth.luggage}</div>
          </Col>
          <Col span={3}>
            <div className="p10">
              <div>
                {reservation.user.firstname} {reservation.user.lastname}
              </div>
              <div>{reservation.user.phone}</div>
            </div>
          </Col>
          <Col span={2}>
            <div className="p10">{filteredPrice(reservation.travel.price)}</div>
          </Col>
        </Row>
      ))}
    </div>
  );
};

export default Home;
