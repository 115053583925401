import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Form, InputNumber, Modal } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { updatePrice } from "../../redux/actions/priceActions";
import "./styles.css";

function PriceModal(props) {
  const { closeModal, price } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const onUpdatePrice = (values) => {
    updatePrice(price._id, values, closeModal, dispatch);
  };

  return (
    <Modal
      title="Modifier les prix"
      open={true}
      onCancel={closeModal}
      footer={() => null}
    >
      <Form
        layout="vertical"
        onFinish={onUpdatePrice}
        form={form}
        initialValues={{
          day: price.day,
          night: price.night,
          weDay: price.weDay,
          weNight: price.weNight,
        }}
      >
        <Form.Item
          label="Nom du lieu de départ"
          name="day"
          tooltip={{
            title:
              "Cette valeur permet de classer l'ordre d'affichage sur le site",
            icon: <InfoCircleOutlined />,
          }}
        >
          <InputNumber
            className="w100"
            placeholder="Code interphone: 1234 - 2ème étage"
            min={1}
          />
        </Form.Item>
        <Form.Item
          label="Adresse de départ"
          name="night"
          tooltip={{
            title: "Ceci est le nom de la catégorie de prestation",
            icon: <InfoCircleOutlined />,
          }}
          rules={[
            {
              required: true,
              message: "Veuillez ajouter le nom de la catégorie!",
            },
          ]}
        >
          <InputNumber
            className="w100"
            placeholder="1 rue Jules Juillet"
            min={1}
          />
        </Form.Item>
        <Form.Item
          label="Nom du lieu d'arrivée"
          name="weDay"
          tooltip={{
            title:
              "Cette valeur permet de classer l'ordre d'affichage sur le site",
            icon: <InfoCircleOutlined />,
          }}
        >
          <InputNumber
            className="w100"
            placeholder="Code interphone: 1234 - 2ème étage"
            min={1}
          />
        </Form.Item>
        <Form.Item
          label="Adresse d'arrivée"
          name="weNight"
          tooltip={{
            title: "Ceci est le nom de la catégorie de prestation",
            icon: <InfoCircleOutlined />,
          }}
          rules={[
            {
              required: true,
              message: "Veuillez ajouter le nom de la catégorie!",
            },
          ]}
        >
          <InputNumber
            className="w100"
            placeholder="1 rue Jules Juillet"
            min={1}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" className="button_primary" htmlType="submit">
            Enregister
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default PriceModal;
