import axios from "axios";
import { toast } from "react-toastify";
import { ITINERARIES_URL } from "../api/intineraryApi";
import {
  addItineraryRequest,
  addItinerarySuccess,
  getItinerariesFailed,
  getItinerariesRequest,
  getItinerariesSuccess,
} from "../reducers/itineraryReducer";

export const getitineraries = (token, dispatch) => {
  dispatch(getItinerariesRequest());
  axios
    .get(ITINERARIES_URL, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch(getItinerariesSuccess(res.data));
    })
    .catch((err) => {
      dispatch(getItinerariesFailed());
      toast(err.response.data.message, { type: "error" });
    });
};

export const addItinerary = (token, body, closeModal, dispatch) => {
  dispatch(addItineraryRequest());
  axios
    .post(ITINERARIES_URL, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      getitineraries(token, dispatch);
      toast(res.data.message, { type: "success" });
      dispatch(addItinerarySuccess());
      closeModal();
    })
    .catch((err) => {
      dispatch(addItinerarySuccess());
      toast(err.response.data.message, { type: "error" });
    });
};
