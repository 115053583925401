import axios from "axios";
import { toast } from "react-toastify";
import { GET_USER_URL, LOGIN_USER_URL } from "../api/userApi";
import {
  loginUserFailed,
  loginUserRequest,
  loginUserSuccess,
} from "../reducers/userReducer";

export const loginUser = (body, dispatch) => {
  dispatch(loginUserRequest());
  axios
    .post(LOGIN_USER_URL, body)
    .then((res) => {
      dispatch(loginUserSuccess(res.data));
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(loginUserFailed());
    });
};

export const getUser = (body, dispatch) => {
  dispatch(loginUserRequest());
  axios
    .post(GET_USER_URL, body)
    .then((res) => {
      console.log(res.data);
      dispatch(loginUserSuccess(res.data));
    })
    .catch(() => {
      dispatch(loginUserFailed());
    });
};
